import type { DeviceLinkedAccessGroup } from '~/types/AccessGroup'
import type { AccountSelection } from '~/types/Account'
import type { AssignedClientsSaveRequest, CacheGroupSettings, Device, DeviceAlarmHistoryData, DeviceBlackListEntry, DeviceCreateRequest, DeviceData, DeviceDirectLinkedClient, DevicelineEntry, DevicePaginationRequest, DeviceSelectionPaginationRequest, DevicesOverviewPaginationResponse, DeviceType, LinkedDevice, PaginationRequest, VirtualMappingInformation } from '~/types/Device'
import type { CommandMetrics, DeviceCommandResponse } from '~/types/DeviceControlPanel'
import type { DeviceWithExclusionPaginationRequest } from '~/types/DeviceSelectionView'
import type { DeviceLocation } from '~/types/DevicesMap'
import type { TablePaginationResponse } from '~/types/GenericTable'
import { api } from '~/utils/backend'

export function paginateStatusOverview(paginationRequest: PaginationRequest) {
  return api.post<DevicesOverviewPaginationResponse>('/devices/paginate/status-overview', paginationRequest)
}

export function paginateDevices(paginationRequest: DevicePaginationRequest) {
  return api.post<TablePaginationResponse<DeviceData>>('/devices/paginate-new', paginationRequest)
}
export function findAvailableTags() {
  return api.get<string[]>('/devices/get/tags')
}

export function findAlarmHistory(hexacode: string) {
  return api.get<DeviceAlarmHistoryData[]>('/devices/alarm-history', {
    params: {
      hexacode: hexacode,
    },
  })
}

export function clearAlarmHistory(hexacode: string) {
  return api.delete<never>('/devices/clear-alarm-history', {
    params: {
      hexacode: hexacode,
    },
  })
}

export function resetFirstConnectionDate(hexacode: string) {
  return api.put<never>(`/devices/${hexacode}/reset-first-connection`)
}

export function deleteDevice(hexacode: string) {
  return api.delete<never>('/devices/delete', {
    params: {
      hexacode: hexacode,
    },
  })
}

export function updateSimNumber(hexacode: string, simNumber: string) {
  return api.post<never>(`/devices/${hexacode}/simNumber/update`, simNumber)
}

export function findDeviceByHexacode(hexacode: string) {
  return api.get<Device>(`/devices/get/${hexacode}`)
}

export function disconnectDevice(hexacode: string) {
  return api.get<never>(`/devices/${hexacode}/disconnect`)
}

export function deleteMapping(mapping: VirtualMappingInformation) {
  return api.post<never>('/devices/delete-mapping', mapping)
}

export function findBlacklistedDevices() {
  return api.get<DeviceBlackListEntry[]>('/devices/blacklist')
}

export function paginateWithExclusion(paginationRequest: DeviceWithExclusionPaginationRequest) {
  return api.post<TablePaginationResponse<DeviceData>>('/devices/paginate/exclude', paginationRequest)
}

export function getDeviceSelection(paginationRequest: DeviceSelectionPaginationRequest) {
  return api.post<TablePaginationResponse<LinkedDevice>>('/devices/paginate/device-selection', paginationRequest)
}

export function downloadFile(exportFilters: unknown) {
  return api.get('/devices/report', { params: exportFilters, responseType: 'blob' })
}

export function findLinkedClientsByHexacode(hexacode: string) {
  return api.get<DeviceDirectLinkedClient[]>(`/devices/${hexacode}/linked-clients`)
}

export function findLinkedAccessGroupsByHexacode(hexacode: string) {
  return api.get<DeviceLinkedAccessGroup[]>(`/devices/${hexacode}/access-groups`)
}

export function findWebClientsByHexacode(hexacode: string) {
  return api.get<AccountSelection[]>(`/devices/${hexacode}/web-clients`)
}

export function saveDevicePermissions(request: AssignedClientsSaveRequest) {
  return api.post(`/devices/${request.hexacode}/permissions/save`, request.permissions)
}

export function getDeviceTimeline(hexacode: string) {
  return api.get<DevicelineEntry[]>(`/devices/${hexacode}/connection-graph`, {
    params: {
      startDate: null,
      endDate: null,
    },
  })
}

export function sendCommand(command: FormData) {
  return api.post<DeviceCommandResponse[]>('/devices/sendCommand', command, { headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function findDeviceResponseChartData(hexacode: string) {
  return api.get<CommandMetrics[]>(`/devices/metrics/${hexacode}`)
}

export function findDeviceSelectionByType(types: DeviceType[]) {
  return api.post<DeviceData[]>('/devices/get/device-selection-by-type', types)
}

export function findRealDeviceDepartments(hexacode: string) {
  return api.get<VirtualMappingInformation[]>('/departments/real/' + hexacode)
}

export function validateHexacode(id: string | null, hexacode: string) {
  return api.get('/devices/validate/hexacode', {
    params: {
      id: id,
      hexacode: hexacode,
    },
  })
}
export function getDefaultCacheSettings() {
  return api.get<CacheGroupSettings[]>('/devices/cache/get')
}

export function saveDevice(device: DeviceCreateRequest) {
  return api.post('/devices/save', device)
}

export function updateDevice(device: DeviceCreateRequest) {
  return api.put('/devices/update', device)
}
export function getDevicesLocation(paginationRequest: DevicePaginationRequest) {
  return api.post<DeviceLocation[]>('/devices/devices-location', paginationRequest)
}
